import React, { useEffect, useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import Header from '../../header/header'
import {makeStyles, Typography} from '@material-ui/core'
import { venueDetails } from '../../../utils'
import TabList from '../../tab-list/tab-list'
import BasePage from '../base'
import api from '../../../api'
import LoadingSpinner from "../../circular-progress/circular-progress";

const useStyles = makeStyles({
    loading: {
        textAlign: 'center'
    },
    tableNumberHeading: {
        textAlign: 'center',
        marginBottom: 20,
    },
})

const ViewTableNumberPage = () => {
    const { brand } = venueDetails.get()
    const { tableNumber } = useParams()
    const [tabs, setTabs] = useState(null)
    const classes = useStyles(brand)

    useEffect(() => {
        const fetchTabsForTable = async () => {
            const result = await api.getAccountsForTable(tableNumber)
            setTabs(result)
        }
        fetchTabsForTable()
    }, [tableNumber])

    if (tabs && tabs.length === 1) {
        return <Redirect to={`/account/${tabs[0].posId}`} />
    }

    return (
        <BasePage>
            <Header />

            <Typography variant="h5" className={classes.tableNumberHeading}>Table {tableNumber}</Typography>

            {tabs === null && (
                <LoadingSpinner />
            )}

            {tabs !== null && (
                <TabList tableNumber={tableNumber} tabs={tabs} />
            )}
        </BasePage>
    )
}

export default ViewTableNumberPage
