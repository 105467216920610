import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import {venueDetails} from '../../utils'

const useStyles = makeStyles({
    loading: {
        textAlign: 'center'
    },
})

const LoadingSpinner = (props) => {
    const { brand } = venueDetails.get()
    const classes = useStyles(brand)
    return (
        <div className={classes.loading}>
            <CircularProgress style={{ color: brand.color.bgPrimary }} {...props} />
        </div>
    )
}

export default LoadingSpinner
