import React, { useEffect, useState } from 'react'
import {makeStyles, Typography, Button} from '@material-ui/core'
import { useParams, Link } from 'react-router-dom';
import Header from '../../header/header'
import BasePage from '../base'
import api from "../../../api";
import LoadingSpinner from "../../circular-progress/circular-progress";
import ItemList from "../../item-list/item-list";
import PaymentSummary from "../../payment-summary/payment-summary";
import {venueDetails} from "../../../utils";
import PaymentOptions from "../../payment-options/payment-options";

const useStyles = makeStyles({
    tabNumberHeading: {
        textAlign: 'center',
        margin: '20px 0',
    },
    instruction: {
        textAlign: 'center',
        marginBottom: 20,
    },
    backLink: {
        margin: '10px 0 20px',
        display: 'block',
        textDecoration: 'underline',
        textAlign: 'center',
        color: '#000'
    },
})

const ViewAccountPage = () => {
    const { brand } = venueDetails.get()
    const classes = useStyles(brand)

    const { chequeNumber } = useParams()
    const [accountData, setAccountData] = useState(null)

    useEffect(() => {
        const fetchAccount = async () => {
            const result = await api.getAccount(chequeNumber)
            setAccountData(result)
        }
        fetchAccount()
    }, [chequeNumber])

    return (
        <BasePage>
            <Header />
            <Typography className={classes.tabNumberHeading}>
                Cheque No {chequeNumber}
            </Typography>

            {!accountData ? (
                <LoadingSpinner />
            ) : (
                <>
                    {accountData.paymentSummary.totalOutstanding.units ? (
                        <PaymentOptions chequeNumber={chequeNumber} />
                    ) : (
                        <>
                            <Typography className={classes.instruction}>
                                There is nothing to pay.
                            </Typography>

                            <Typography>
                                <Link className={classes.backLink} to="/table">Back</Link>
                            </Typography>
                        </>
                    )}

                    <PaymentSummary {...accountData.paymentSummary} />

                    <ItemList items={accountData.items} />
                </>
            )}

        </BasePage>
    )
}

export default ViewAccountPage
