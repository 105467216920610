import React from 'react'
import QRCode from 'qrcode.react'
import { makeStyles, Typography } from '@material-ui/core'
import {Link, useParams} from 'react-router-dom';
import Header from '../../header/header'
import BasePage from '../base'
import {venueDetails} from '../../../utils'

const useStyles = makeStyles({
    tabNumberHeading: {
        textAlign: 'center',
        margin: '20px 0',
    },
    instruction: {
        textAlign: 'center',
        margin: '10px 10px 30px',
        fontSize: 12,
    },
    qrContainer: {
        textAlign: 'center'
    },
    backLink: {
        padding: 20,
        margin: 20,
        textAlign: 'center',
        display: 'block',
        textDecoration: 'underline',
        color: '#000'
    },
})

const PayAndGoQrPage = () => {
    const classes = useStyles()

    const { chequeNumber } = useParams()
    const { venue } = venueDetails.get()

    return (
        <BasePage>
            <Header />
            <Typography className={classes.instruction}>
                Scan this QR code with your mobile device to settle up using the OrderPay App. <br/> <br />
                No install needed!
            </Typography>

            <div className={classes.qrContainer}>
                <QRCode
                    size={180}
                    value={`https://order.alpha.orderpay.com/venue/${venue.id}/pay-go/order/${chequeNumber}`}
                />
            </div>

            <Typography>
                <Link className={classes.backLink} to={`/account/${chequeNumber}`}>Back</Link>
            </Typography>
        </BasePage>
    )
}

export default PayAndGoQrPage
