const createLocalStorageItem = (key) => ({
    get: () => JSON.parse(localStorage.getItem(key)),
    set: (value) => localStorage.setItem(key, JSON.stringify(value)),
    del: () => localStorage.removeItem(key)
})

export const isAuthenticated = createLocalStorageItem('isAuthenticated')

export const deviceId = createLocalStorageItem('deviceId')

export const isInvalidDevice = createLocalStorageItem('isInvalidDevice')

export const venueDetails = createLocalStorageItem('venueDetails')

export const terminalDetails = createLocalStorageItem('terminalDetails')

export const deviceDetails = createLocalStorageItem('deviceDetails')

export const accountDetails = createLocalStorageItem('accountDetails')

export const penceToMoney = (pence) => (pence / 100).toFixed(2)
