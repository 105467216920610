import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Link, Redirect, useParams } from 'react-router-dom';
import Header from '../../header/header'
import BasePage from '../base'
import api from '../../../api';
import LoadingSpinner from '../../circular-progress/circular-progress';
import {accountDetails, penceToMoney} from '../../../utils';
import stripe from '../../../stripe-terminal'
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles({
    tabNumberHeading: {
        textAlign: 'center',
        margin: '20px 0 40px',
    },
    paymentInfo: {
        textAlign: 'center',
        color: '#000',
        textDecoration: 'none'
    },
    paymentAmount: {
        fontSize: 48
    },
    backLink: {
        padding: 20,
        marginTop: 10,
        display: 'block',
        textDecoration: 'underline',
        color: '#000'
    },
    error: {
        margin: 30,
        color: '#888'
    }
})

const PayPage = () => {
    const [stage, setStage] = useState(0)
    const classes = useStyles()

    const { chequeNumber } = useParams()
    const [accountData, setAccountData] = useState(null)
    const [requestPaymentIntentResult, setRequestPaymentIntentResult] = useState(null)
    const [paymentResult, setPaymentResult] = useState(null)

    const createPaymentIntent = async (accountInfo) => {
        await stripe.setDisplay(accountInfo)

        let paymentIntent = requestPaymentIntentResult
        if (!paymentIntent) {
            paymentIntent = await api.createPaymentIntent(chequeNumber)
            setRequestPaymentIntentResult(paymentIntent)
        }
        setStage(1)
        const paymentResult = await stripe.takePayment(paymentIntent.paymentIntentSecret)
        setPaymentResult(paymentResult)
        if (paymentResult.success) {
            api.capturePayment(paymentResult.processResult.paymentIntent.id)
            setStage(2)
            setTimeout(() => {
                setStage(3)
            }, 12000)
        } else {
            setStage(-1)
        }
    }

    useEffect(() => {
        const fetchAccount = async () => {
            const result = await api.getAccount(chequeNumber)
            setAccountData(result)
            createPaymentIntent(result)
        }
        fetchAccount()
    }, [chequeNumber])

    const amountToPay = requestPaymentIntentResult?.amount ?? accountData?.paymentSummary.totalOutstanding.units

    const showSummary = stage !== 2 && stage > 0

    if (stage === 3) {
        accountDetails.del()
        return <Redirect to={`/account/${chequeNumber}`} />
    }

    return (
        <BasePage>
            <Header />
            <Typography className={classes.tabNumberHeading}>
                Cheque No {chequeNumber}
            </Typography>

            {!accountData ? (
                <LoadingSpinner />
            ) : (
                <>
                {showSummary && (
                    <div className={classes.paymentInfo}>
                        <Typography>
                            To Pay:
                        </Typography>
                        <Typography className={classes.paymentAmount}>
                            £{penceToMoney(amountToPay)}
                        </Typography>
                    </div>
                )}

                    {stage === 0 && (
                        <div className={classes.paymentInfo}>
                            <LoadingSpinner />
                            <Typography>Making payment request...</Typography>
                        </div>
                    )}
                    {stage === 1 && (
                        <div className={classes.paymentInfo}>
                            <LoadingSpinner />
                            <Typography>
                                Waiting for card to be presented to Terminal...
                            </Typography>

                            <Typography>
                                <Link
                                    className={classes.backLink}
                                    onClick={() => {
                                        stripe.cancelPayment()
                                    }}
                                    to={`/account/${chequeNumber}`}
                                >
                                    Cancel
                                </Link>
                            </Typography>
                        </div>
                    )}
                    {stage === 2 && (
                        <div className={classes.paymentInfo}>
                            <Typography className={classes.paymentInfo}>
                                <CheckCircleIcon className={classes.checkIcon} />
                            </Typography>
                            <Typography className={classes.paymentInfo}>
                                Payment complete
                            </Typography>
                        </div>
                    )}
                    {stage === -1 && (
                        <div className={classes.paymentInfo}>
                            <Typography className={classes.paymentInfo}>
                                Something went wrong.
                            </Typography>

                            <Typography className={classes.backLink} onClick={() => {
                                setStage(0)
                                createPaymentIntent()
                            }}>
                                Press here to try again
                            </Typography>

                            <Typography className={classes.error}>
                                {JSON.stringify(paymentResult.error, null, 2)}
                            </Typography>
                        </div>
                    )}
                </>
            )}

        </BasePage>
    )
}

export default PayPage
