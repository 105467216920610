import React from 'react';
import { Redirect } from 'react-router-dom'
import { terminalDetails } from '../../../utils';

const LoggedInRedirectPage = () => {
    if (!terminalDetails.get()) {
        return <Redirect to="/terminal-setup" />
    }

    return <Redirect to="/table" />
}

export default LoggedInRedirectPage
