import { loadStripeTerminal } from '@stripe/terminal-js';
import api from './api'
import { deviceDetails, terminalDetails } from './utils'

class StripeTerminal {
    ready = false
    terminal = null
    isInitialising = false

    constructor() {
        this.init()
    }

    async init() {
        if (!this.isInitialising) {
            this.isInitialising = new Promise(async (resolve) => {
                const StripeTerminal = await loadStripeTerminal();

                this.terminal = StripeTerminal.create({
                    onFetchConnectionToken: async () => {
                        const result = await api.getStripeConnectionToken()
                        return result.connectionToken
                    },
                    onUnexpectedReaderDisconnect: () => {
                        const reconnect = async () => {
                            await stripe.connectReader(terminalDetails.get())
                        }
                        reconnect()
                    }
                })

                const connectedReader = await stripe.terminal.getConnectedReader()
                if (!connectedReader) {
                    const locationId = deviceDetails.get()?.stripeLocationId
                    if (!locationId) {
                        // TODO: Handle error
                        this.isInitialising = false
                        return;
                    }
                    const discoverResult = await this.terminal.discoverReaders({
                        location: locationId,
                    })
                    if (discoverResult.error) {
                        // TODO: Handle error
                        this.isInitialising = false
                        return
                    }
                    if (!discoverResult.discoveredReaders || !discoverResult.discoveredReaders.length) {
                        window.location.hash = '#/terminal-registration' // HACK: Sort this out..
                        this.isInitialising = false
                        return
                    }
                    const connectResult = await this.terminal.connectReader(discoverResult.discoveredReaders[0])
                    if (connectResult.error) {
                        // TODO: Handle error
                        this.isInitialising = false
                        return
                    }
                }
                this.ready = true
                resolve();
            })
        } else {
            return this.isInitialising
        }
    }

    async discoverReaders() {
        if (!this.ready) {
            await this.init()
        }
        const locationId = deviceDetails.get().stripeLocationId
        return this.terminal.discoverReaders({
            location: locationId,
        })
    }

    async takePayment(paymentIntentSecret) {
        if (!this.ready) {
            await this.init()
        }

        const paymentResult = await this.terminal.collectPaymentMethod(paymentIntentSecret);
        if (paymentResult.error) {
            return {
                success: !paymentResult.error, from: 'paymentResult', ...paymentResult
            }
        }
        const processResult = await this.terminal.processPayment(paymentResult.paymentIntent)
        if (processResult.error) {
            return {
                success: !paymentResult.error, from: 'processResult', ...processResult
            }
        } else if (processResult.paymentIntent) {
           return {
               success: true, processResult, paymentResult,
           }
        }
    }

    async cancelPayment() {
        if (!this.ready) {
            await this.init()
        }

        const cancelResult = await this.terminal.cancelCollectPaymentMethod()
        return { success: true, cancelResult }
    }

    async setDisplay(accountData) {
        if (!this.ready) {
            await this.init()
        }

        const setDisplayResult = await this.terminal.setReaderDisplay({
            type: 'cart',
            cart: {
                line_items: [
                    ...(accountData.items ?? []).map((item) => ({
                        description: item.name,
                        amount: item.price.units,
                        quantity: item.quantity,
                    })),
                    ...(accountData.taxes ?? []).map((tax) => ({
                        description: tax.name,
                        amount: tax.price.units,
                        quantity: 1,
                    }))
                ],
                total: accountData.paymentSummary.totalOutstanding.units,
                currency: 'gbp',
            },
        })
        return { success: true, setDisplayResult }
    }
}

const stripe = new StripeTerminal()
export default stripe
