import React from 'react'
import { Link } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'
import { venueDetails } from '../../utils'

const useStyles = makeStyles({
    link: {
        padding: 16,
        background: props => props.color.bgPrimary,
        lineHeight: '64px',
        margin: 20,
        textDecoration: 'none',
        '& span': {
            color: props => props.color.lblPrimary,
        }
    },
    linkContainer: {
        textAlign: 'center',
        marginBottom: 10,
    }
})

const PaymentOptions = ({ chequeNumber }) => {
    const { brand } = venueDetails.get()
    const classes = useStyles(brand)

    return (
        <div className={classes.linkContainer}>
            <Link className={classes.link} to={`/account/${chequeNumber}/pay`}>
                <Button>Pay</Button>
            </Link>

            <Link className={classes.link} to={`/account/${chequeNumber}/pay/split`}>
                <Button>Split</Button>
            </Link>

            <Link className={classes.link} to={`/account/${chequeNumber}/pay-and-go-qr`}>
                <Button>Self-serve (QR)</Button>
            </Link>
        </div>
    )
}

export default PaymentOptions
