import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {penceToMoney} from "../../utils";

const useStyles = makeStyles({
    container: {
        paddingBottom: 30
    },
    noItems: {
        textAlign: 'center',
        marginTop: 30,
    },
    backLink: {
        padding: 20,
        marginTop: 50,
        display: 'block',
        textDecoration: 'underline',
        color: '#000'
    },
    itemContainer: {
        padding: '5px 10px 0',
    },
    mainItem: {
        '& p': {
            display: 'inline-block',
            fontSize: 12,
            '&:first-child': {
                width: 40,
                verticalAlign: 'top'
            },
            '&:nth-child(2)': {
                width: 'calc(100% - 105px)',
                verticalAlign: 'top'
            },
            '&:nth-child(3)': {
                width: 65,
                verticalAlign: 'top',
                textAlign: 'right',
            }
        },
    }
})

const modifierUseStyles = makeStyles({
    modifierContainer: {
        marginLeft: 40,
    },
    modifierItem: {
        '& p': {
            display: 'inline-block',
            fontSize: 10,
            color: '#999',
            '&:first-child': {
                width: '100%'
            }
        }
    }
})

const Modifiers = ({ modifiers = [] }) => {
    const classes = modifierUseStyles()

    return (modifiers ?? []).map((modifier) => (
        <>
            <div className={classes.modifierContainer}>
                <div className={classes.modifierItem}>
                    <Typography>{modifier.name}</Typography>
                </div>
            </div>
            <Modifiers modifiers={modifier.modifiers} />
        </>
))}

const ItemList = ({ items, tableNumber }) => {
    const classes = useStyles()

    if (!(items ?? []).length) {
        return (
            <div className={classes.noItems}>
                <Typography>
                    This account is empty
                </Typography>

                <Typography>
                    <Link className={classes.backLink} to={`/table/${tableNumber}`}>Back</Link>
                </Typography>
            </div>
        )
    }

    return (
        <div className={classes.container}>
            {items.map((item) => (
                <div className={classes.itemContainer}>
                    <div className={classes.mainItem}>
                        <Typography>{item.quantity}x</Typography>
                        <Typography>{item.name}</Typography>
                        <Typography>£{penceToMoney(item.price.units)}</Typography>
                    </div>
                    <div className={classes.modifiers}>
                        <Modifiers modifiers={item.modifiers} />
                    </div>
                </div>
            ))}
        </div>
        )
}

export default ItemList
