import React, { useState } from 'react'
import { IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import AddLinkIcon from '@material-ui/icons/InsertLink'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useHistory } from 'react-router-dom'
import Header from '../../header/header'
import BasePage from '../base'
import { terminalDetails, venueDetails } from '../../../utils'
import api from '../../../api'
import LoadingSpinner from '../../circular-progress/circular-progress'

const useStyles = makeStyles({
    pageContainer: {
        margin: '20px 30px'
    },
    instruction: {
        textAlign: 'center',
    },
    tableInputContainer: {
        textAlign: 'center',
        marginTop: 30
    },
    tableInput: {
        backgroundColor: props => `${props.color.bgPrimary} !important`,
        '& input': {
            background: '#eee',
            padding: '14px 8px',
            fontSize: 16
        }
    },
    tableInputAdornment: {
        color: props => props.color.lblPrimary,
        width: 48,
        height: 48,
        margin: 0,
        padding: 0,
        borderRadius: 0,
    },
    checkIcon: {
        fontSize: 64
    },
    instructionError: {
        marginTop: 20,
        textAlign: 'center',
        color: '#f00',
    },
    registering: {
        marginTop: 20
    }
})

const TerminalSetupPage = () => {
    const { brand } = venueDetails.get()
    const history = useHistory()
    const classes = useStyles(brand)
    const [pairingCode, setPairingCode] = useState(null)
    const [stage, setStage] = useState(0)
    const [isRegistrationError, setIsRegistrationError] = useState(false)

    return (
        <>
            <Header />

            <div className={classes.pageContainer}>
                <Typography className={classes.instruction}>
                    Enter Terminal Pairing Code, then press the Link icon.
                </Typography>

                {stage === 0 && (
                    <div className={classes.tableInputContainer}>
                        <form onSubmit={async () => {
                            setStage(1)
                            setIsRegistrationError(false)
                            const result = await api.registerTerminal(pairingCode)
                            if (result instanceof Error) {
                                setStage(0)
                                setIsRegistrationError(true)
                                return
                            }
                            terminalDetails.set(result)
                            setStage(2)
                            setTimeout(() => {
                                history.push('/table')
                            }, 10000)
                        }}>
                            <TextField
                                fullWidth
                                variant="filled"
                                placeholder="kitten-month-apple"
                                onChange={(e) => setPairingCode(e.target.value)}
                                InputProps={{
                                    className: classes.tableInput,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                type="submit"
                                                className={classes.tableInputAdornment}
                                            >
                                                <AddLinkIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            {isRegistrationError && (
                                <Typography className={classes.instructionError}>
                                    Registration failed. Please check the pairing code and try again.
                                </Typography>
                            )}
                        </form>
                    </div>
                )}

                {stage === 1 && (
                    <>
                        <LoadingSpinner className={classes.registering} />
                        <Typography className={classes.instruction}>
                            Registering terminal...
                        </Typography>
                    </>
                )}

                {stage === 2 && (
                    <>
                        <Typography className={classes.instruction}>
                            <CheckCircleIcon className={classes.checkIcon} />
                        </Typography>
                        <Typography className={classes.instruction}>
                            Device registered.
                        </Typography>
                    </>
                )}
            </div>
        </>
    )
}

export default TerminalSetupPage
