import fetch from 'isomorphic-fetch'
import { v4 as uuid } from 'uuid'
import {deviceId, isAuthenticated, isInvalidDevice, venueDetails, deviceDetails, accountDetails} from './utils'

let BASE_URL = 'http://localhost:3050'
if (process.env.REACT_APP_BUILD_TYPE === 'development') {
    BASE_URL = 'https://service-payment-terminals.alpha.orderpay.com'
} else if (process.env.REACT_APP_BUILD_TYPE === 'staging') {
    BASE_URL = 'https://service-payment-terminals.staging.orderpay.com'
} else if (process.env.REACT_APP_BUILD_TYPE === 'production') {
    BASE_URL = 'https://service-payment-terminals.orderpay.com'
}

const _fetch = async (url, options = {}) => {
    const deviceFingerprint = {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        product: navigator.product,
        productSub: navigator.productSub,
        userAgent: navigator.userAgent,
        vendor: navigator.vendor,
        vendorSub: navigator.vendorSub
    }
    return await fetch(url , {
        ...options,
        headers: {
            Authorization: deviceId.get(),
            'x-device-fingerprint': btoa(JSON.stringify(deviceFingerprint)),
            'Content-Type': 'application/json'
        }
    })
}

const api = {
    signIn: async () => {
        let didSetDeviceId = false
        if (!deviceId.get()) {
            deviceId.set(`${uuid()}-${uuid()}`)
            didSetDeviceId = true
        }
        const response = await _fetch(`${BASE_URL}/auth`, {
            method: 'POST'
        })
        if (response.status === 200) {
            const { device, ...result } = await response.json()
            venueDetails.set(result)
            deviceDetails.set(device)
            isAuthenticated.set(true)
            isInvalidDevice.del()
            return {
                auth: true,
                didSetDeviceId
            }
        }
        return {
            auth: false,
            didSetDeviceId,
        }
    },
    getAccountsForTable: async (tableNumber) => {
        const response = await _fetch(`${BASE_URL}/table/${tableNumber}`)
        if (response.status === 200) {
            const result = await response.json()
            return result.tabs
        }
        return new Error()
    },
    getAccount: async (posId) => {
        const lastAccount = accountDetails.get()
        if (lastAccount?.posId === posId) {
            return lastAccount
        }
        const response = await _fetch(`${BASE_URL}/account/${posId}`)
        if (response.status === 200) {
            const result = await response.json()
            accountDetails.set(result.tab)
            setTimeout(() => {
                accountDetails.del()
            }, 10000)
            return result.tab
        }
        return new Error()
    },
    getStripeConnectionToken: async () => {
        const response = await _fetch(`${BASE_URL}/stripe/connection-token`)
        if (response.status === 200) {
            return response.json()
        }
        return new Error()
    },
    registerTerminal:  async (pairingCode) => {
        const response = await _fetch(`${BASE_URL}/stripe/register-terminal`, {
            method: 'POST',
            body: JSON.stringify({
                pairingCode,
            })
        })
        if (response.status === 200) {
            return response.json()
        }
        return new Error(response.error)
    },
    createPaymentIntent: async (chequeNumber) => {
        const response = await _fetch(`${BASE_URL}/payment-request/${chequeNumber}`)
        if (response.status === 200) {
            return response.json()
        }
        return new Error(response.error)
    },
    capturePayment: async (paymentIntendId) => {
        const response = await _fetch(`${BASE_URL}/payment-request/capture/${paymentIntendId}`)
        if (response.status === 200) {
            return response.json()
        }
        return new Error(response.error)
    },
}

export default api
