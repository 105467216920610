import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import Header from '../../header/header'
import BasePage from '../base'
import { venueDetails } from '../../../utils'

const useStyles = makeStyles({
    instruction: {
        textAlign: 'center',
        marginTop: 20
    },
    tableInputContainer: {
        textAlign: 'center',
        marginTop: 30
    },
    tableInput: {
        backgroundColor: props => `${props.color.bgPrimary} !important`,
        '& input': {
            background: '#eee',
            padding: '20px 12px',
            fontSize: 20
        }
    },
    tableInputAdornment: {
        color: props => props.color.lblPrimary,
        width: 64,
        height: 64,
        margin: 0,
        padding: 0,
        borderRadius: 0,
    },
    pageContainer: {
        margin: '0 20px'
    }
})

const EnterTableNumberPage = () => {
    const { brand } = venueDetails.get()
    const [tableNumber, setTableNumber] = useState(null)
    const classes = useStyles(brand)
    const history = useHistory();

    return (
        <BasePage>
            <Header />
            <div className={classes.pageContainer}>
                <Typography className={classes.instruction}>
                    Enter Table number
                </Typography>
                <div className={classes.tableInputContainer}>
                    <form onSubmit={() => {
                        if (!tableNumber) {
                            return
                        }
                        history.push(`/table/${tableNumber}`);
                    }}>
                        <TextField
                            type="number"
                            variant="filled"
                            placeholder="Table No."
                            onChange={(e) => setTableNumber(e.target.value)}
                            InputProps={{
                                className: classes.tableInput,
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            type="submit"
                                            className={classes.tableInputAdornment}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </form>
                </div>
            </div>
        </BasePage>
    )
}

export default EnterTableNumberPage
