import React, { useEffect } from 'react'
import { makeStyles, Typography, CircularProgress } from '@material-ui/core'
import api from '../../../api'
import { deviceId, isAuthenticated, isInvalidDevice } from '../../../utils'
import LoadingSpinner from "../../circular-progress/circular-progress";

const useStyles = makeStyles({
    pageContainer: {
        textAlign: 'center',
        paddingTop: 150,
    },
    deviceId: {
        color: '#666',
        marginTop: 30,
        fontSize: 14,
    },
})

const LoginPage = () => {
    const classes = useStyles()

    useEffect(() => {
        if (!isAuthenticated.get()) {
            const signIn = async () => {
                const result = await api.signIn()
                if (result.auth) {
                    window.location.reload()
                } else {
                    isInvalidDevice.set(true)
                    if (result.didSetDeviceId) {
                        window.location.reload()
                    }
                }
            }
            signIn()
        }
    })

    return (
        <div className={classes.pageContainer}>
            {isInvalidDevice ? (
                <>
                    <Typography>
                        Access denied.
                    </Typography>
                    <Typography className={classes.deviceId}>
                        Device ID <br />
                        {deviceId.get()}
                    </Typography>
                </>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
}

export default LoginPage
