import React, { useState, useEffect } from 'react'
import { makeStyles, Typography}  from '@material-ui/core'
import { Redirect, useHistory } from 'react-router-dom'
import stripe from '../../../stripe-terminal'
import { terminalDetails } from '../../../utils'
import LoadingSpinner from "../../circular-progress/circular-progress";

const useStyles = makeStyles({
    pageContainer: {
        textAlign: 'center',
        margin: '150px 0'
    },
})

const TerminalSetupPage = () => {
    const [stage, setStage] = useState(0)

    useEffect(() => {
        const discoverTerminals = async () => {
            const terminals = await stripe.discoverReaders()

            if (terminals.error) {
                console.error(terminals.error)
            } else if (terminals.discoveredReaders.length) {
                const selectedTerminal = terminals.discoveredReaders[0]
                terminalDetails.set(selectedTerminal)
                setStage(1)
                setTimeout(() => {
                    setStage(2)
                }, 1500)
            } else {
                setStage(-1)
            }
        }
        discoverTerminals()
    }, [])

    const classes = useStyles()

    if (stage === -1) {
        return (<Redirect to="/terminal-registration" />)
    }

    return (
        <div className={classes.pageContainer}>
            <LoadingSpinner variant="determinate" value={stage * 100 ?? 1} />
            {stage === 0 && (
                <Typography>
                    Searching for terminal...
                </Typography>
            )}

            {stage === 1 && (
                <Typography>
                    Found!
                </Typography>
            )}

            {stage === 2 && (
                <Redirect to="/table" />
            )}
        </div>
    )
}

export default TerminalSetupPage
