import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import stripe from '../../stripe-terminal';
import { terminalDetails } from '../../utils';
import LoadingSpinner from '../circular-progress/circular-progress'

const BasePage = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false)
    const [doRedirect, setDoRedirect] = useState(false)

    useEffect(() => {
        const terminal = terminalDetails.get()
        if (!terminal) {
            setDoRedirect(true)
        }
        const connectReader = async () => {
            await stripe.init()
            setIsConnected(true)
        }
        connectReader()
    }, [isConnected])

    if (doRedirect) {
        return <Redirect to="/terminal-setup" />
    }
    return isConnected ? children : (
        <>
            <LoadingSpinner style={{
                marginTop: '100px'
            }} />
            <Typography style={{
                textAlign: 'center'
            }}>
                Connecting to terminal...
            </Typography>
        </>
    )
}

export default BasePage
