import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import black from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'
import { HashRouter as Router, Route } from 'react-router-dom'
import LoginPage from './components/pages/login/login'
import TerminalSetupPage from './components/pages/terminal-setup/terminal-setup'
import EnterTableNumberPage from './components/pages/enter-table-number/enter-table-number'
import ViewTableNumberPage from './components/pages/view-table-number/view-table-number'
import ViewAccountPage from './components/pages/view-account/view-account'
import ShowPayAndGoQrForAccountPage from './components/pages/pay-and-go-qr/pay-and-go-qr'
import TerminalRegistrationPage from './components/pages/terminal-registration/terminal-registration'
import LoggedInRedirectPage from './components/pages/logged-in-redirect/logged-in-redirect'
import PayPage from './components/pages/pay/pay'
import { isAuthenticated } from './utils'

require('./stripe-terminal')

const App = () => {
    const theme = createMuiTheme({
        palette: {
            primary: black,
        },
        typography: {
            fontFamily: 'Montserrat, arial, helvetica, sans-serif',
            allVariants: {
                color: grey.A400
            }
        }
    });

    if (!isAuthenticated.get()) {
        return (<LoginPage />)
    }

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Route exact path="/table/:tableNumber" component={ViewTableNumberPage} />
                <Route exact path="/table" component={EnterTableNumberPage} />
                <Route exact path="/account/:chequeNumber/pay-and-go-qr" component={ShowPayAndGoQrForAccountPage} />
                <Route exact path="/account/:chequeNumber/pay/split" component={ViewAccountPage} />
                <Route exact path="/account/:chequeNumber/pay" component={PayPage} />
                <Route exact path="/account/:chequeNumber" component={ViewAccountPage} />
                <Route exact path="/terminal-registration" component={TerminalRegistrationPage} />
                <Route exact path="/terminal-setup" component={TerminalSetupPage} />
                <Route exact path="/" component={LoggedInRedirectPage} />
            </Router>
        </ThemeProvider>
    );
}

export default App;
