import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { penceToMoney } from '../../utils'

const useStyles = makeStyles({
    container: {
        border: '1px dashed #ddd',
        padding: 10,
    },
    label: {
        display: 'inline-block',
        width: 'calc(100% - 80px)',
        fontSize: 12,
    },
    value: {
        display: 'inline-block',
        width: 80,
        textAlign: 'right',
        fontSize: 12,
    },
    total: {
        fontWeight: 700,
        textDecoration: 'underline',
        marginTop: 10,
    }
})

const PaymentSummary = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div>
                <Typography className={classes.label}>
                    Subtotal
                </Typography>
                <Typography className={classes.value}>
                    £{penceToMoney(props.subTotal.units)}
                </Typography>
            </div>
            {(props.taxes ?? []).map((tax) => (
                <div>
                    <Typography className={classes.label}>
                        {tax.name}
                    </Typography>
                    <Typography className={classes.value}>
                        £{penceToMoney(tax.price.units)}
                    </Typography>
                </div>
            ))}
            <div>
                <Typography className={classes.label}>
                    Total
                </Typography>
                <Typography className={classes.value}>
                    £{penceToMoney(props.adjustedSubTotal.units)}
                </Typography>
            </div>
            <div>
                <Typography className={classes.label}>
                    Already Paid
                </Typography>
                <Typography className={classes.value}>
                    £{penceToMoney(props.alreadyPaid.units)}
                </Typography>
            </div>
            <div>
                <Typography className={`${classes.label} ${classes.total}`}>
                    AMOUNT OUTSTANDING
                </Typography>
                <Typography className={`${classes.value} ${classes.total}`}>
                    £{penceToMoney(props.totalOutstanding.units)}
                </Typography>
            </div>
        </div>
    )
}

export default PaymentSummary
