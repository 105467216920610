import React from 'react';
import { makeStyles, Typography } from '@material-ui/core'
import { venueDetails } from '../../utils'

const useStyles = makeStyles({
    logo: {
        maxWidth: '100%',
        maxHeight: '12.5vh',
        left: '50%',
        transform: 'translateX(-50%)',
        border: '1px solid #444',
        borderRadius: 10,
        position: 'absolute',
        top: '2.5vh',
        zIndex: 1000,
    },
    logoDivider: {
        top: -5,
        minHeight: '30vh',
        width: '100%',
        zIndex: -1,
        position: 'relative',
        clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
    },
    venueName: {
        paddingTop: '18vh',
        textAlign: 'center'
    }
})

const Header = () => {
    const classes = useStyles()
    const { venue, brand } = venueDetails.get()

    return (
        <div>
            {venue.logoUrl ? (
                <img alt={venue.name} className={classes.logo} src={venue.logoUrl}/>
            ) : null}
            <div className={classes.logoDivider} style={{
                backgroundColor: brand.color.bgPrimary
            }}>
                <Typography className={classes.venueName} variant="h6" style={{
                    color: brand.color.lblPrimary
                }}>
                    {venue.name}
                </Typography>
            </div>
        </div>
    )
}

export default Header
