import React from 'react'
import {IconButton, makeStyles, Typography} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ChevronRight } from "@material-ui/icons";
import {penceToMoney} from "../../utils";

const useStyles = makeStyles({
    noOpenAccounts: {
        textAlign: 'center',
        marginTop: 30,
    },
    backLink: {
        padding: 20,
        marginTop: 50,
        display: 'block',
        textDecoration: 'underline',
        color: '#000'
    },
    tabContainer: {
        border: '1px solid #ccc',
        padding: 30,
        marginBottom: 15,
    },
    tabDataItemContainer: {
      display: 'flex',
    },
    tabDataItem: {
        flex: 'auto',
    },
    tabLabel: {
        display: 'block',
        fontWeight: 700,
    },
    tabValue: {
        display: 'block',
    },
    tabLink: {
        textDecoration: 'none',
    }
})

const TabList = ({ tabs }) => {
    const classes = useStyles()

    if (!(tabs ?? []).length) {
        return (
            <div className={classes.noOpenAccounts}>
                <Typography>
                    There are no open accounts.
                </Typography>

                <Typography>
                    <Link className={classes.backLink} to="/table">Back</Link>
                </Typography>
            </div>
        )
    }

    return tabs.map((tab) => (
        <Link className={classes.tabLink} to={`/account/${tab.posId}`}>
            <div className={classes.tabContainer}>
                <Typography className={classes.tabDataItemContainer}>
                    <span className={classes.tabDataItem}>
                        <span className={classes.tabLabel}>
                            Check No.
                        </span>
                        <span className={classes.tabValue}>
                            {tab.posId}
                        </span>
                    </span>
                    <span className={classes.tabDataItem}>
                        <span className={classes.tabLabel}>
                            Total
                        </span>
                        <span className={classes.tabValue}>
                            £{penceToMoney(tab.total.units)}
                        </span>
                    </span>
                    <span className={classes.tabDataItem}>
                        <IconButton>
                            <ChevronRight />
                        </IconButton>
                    </span>
                </Typography>
            </div>
        </Link>
    ))
}

export default TabList
